<template>
  <AppHeader />
  <NuxtPage class="page-content" />
  <AppFooter />
</template>

<style lang="scss">
  .page-content {
    min-height: 100%;
    position: relative;
    padding-top: calc(var(--logo-height) + calc(var(--topbar-padding-vertical) * 2));
  }
</style>
